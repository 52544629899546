import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Button, Columns, Container, Form, Heading, Icon, Section } from 'react-bulma-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faShieldAlt, faUser } from '@fortawesome/free-solid-svg-icons'

const SignUpAsClientPage = () => (
  <Layout>
    
    <Section size='medium'>
      <Container>
        <Columns centered gap={8} className='has-border-right'>
          <Columns.Column size={6}>
            <Heading size={5} textColor='dark'>
              S'inscrire à Exalto en tant que client
            </Heading>
            <form>
              <Form.Field horizontal>
                <Form.Field.Label>
                  <Form.Label>
                    Nom et Prénom
                  </Form.Label>
                </Form.Field.Label>
                <Form.Field.Body>
                  <Form.Field>
                    <Form.Control>
                      <Icon align='left'>
                        <FontAwesomeIcon icon={faUser} />
                      </Icon>
                      <Form.Input
                        placeholder='Votre nom'
                      />
                    </Form.Control>
                  </Form.Field>
                  <Form.Field>
                    <Form.Control>
                      <Icon align='left'>
                        <FontAwesomeIcon icon={faUser} />
                      </Icon>
                      <Form.Input
                        placeholder='Votre prénom'
                      />
                    </Form.Control>
                  </Form.Field>
                </Form.Field.Body>
              </Form.Field>

              <Form.Field horizontal>
                <Form.Field.Label>
                  <Form.Label>
                    Email
                  </Form.Label>
                </Form.Field.Label>
                <Form.Field.Body>
                  <Form.Field>
                    <Form.Control>
                      <Icon align='left'>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </Icon>
                      <Form.Input
                        type='email'
                      />
                    </Form.Control>
                  </Form.Field>
                </Form.Field.Body>
              </Form.Field>
              <Form.Field horizontal>
                <Form.Field.Label>
                  <Form.Label>
                    Mot de passe
                  </Form.Label>
                </Form.Field.Label>
                <Form.Field.Body>
                  <Form.Field>
                    <Form.Control>
                      <Icon align='left'>
                        <FontAwesomeIcon icon={faShieldAlt} />
                      </Icon>
                      <Form.Input
                        type='password'
                      />
                    </Form.Control>
                  </Form.Field>
                </Form.Field.Body>
              </Form.Field>
              <Form.Field horizontal>
                <Form.Field.Label>

                </Form.Field.Label>
                <Form.Field.Body>

                  <Form.Control>
                    <Button
                      type='submit'
                      color='primary'
                    >
                      Créer un compte
                    </Button>
                  </Form.Control>
                </Form.Field.Body>
              </Form.Field>
            </form>
          </Columns.Column>
        </Columns>
      </Container>
    </Section >
  </Layout >
)

export default SignUpAsClientPage

export const query = graphql`
  query($language: String!) {
          locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
          node {
          ns
          data
          language
        }
      }
    }
  }
`